import firebase from 'firebase';
import store from '../store';

// Actions
const SIGN_IN   = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const MODIFY_USER = 'MODIFY_USER';

//Default state
const DEFAULT_STATE = {
    user: null
}

// Reducer
export default function reducer(state = DEFAULT_STATE, action = {}) {
    switch (action.type) {
        case SIGN_IN : {
            const newState = {...state};
            newState.user = action.value;
            newState.user.isCompanyAdmin = newState.user.email.indexOf('-admin@khra.com') !== -1;
            return newState;
        }
        case SIGN_OUT : {
            const newState = {...state};
            newState.user = null;
            return newState;
        }
        default: return state;
    }
}

// Action Creators
export function signIn(user) {
    return { type: SIGN_IN, value: user };
}

export function signOut() {
    return { type: SIGN_OUT };
}

export function modifyUser(data) {
    return { type: MODIFY_USER, value: data};
}