import {createStore, applyMiddleware, combineReducers} from 'redux';
import auth from './auth/main'
import thunk from 'redux-thunk';

const store = createStore(
    combineReducers({
        auth
    }),
    applyMiddleware(thunk)
);

export default store;

